import "./watch.scss"
import {ArrowBackOutlined} from "@material-ui/icons";
import video from "../../video/You&Me.mp4";

const Watch = () =>{
    return (
        <div className="watch">
            <a className="back" href="/home">
                <ArrowBackOutlined/>
                Home
            </a>
            <video
                className="video"
                autoPlay
                progress
                controls
                src={video}/>
        </div>
    )
}

export default Watch;