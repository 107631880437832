import "./featured.scss"
import {InfoOutlined, PlayArrow} from "@material-ui/icons";
import background_image from "../../img/featured/background.jpg"
import show_logo from "../../img/featured/logo.png"
import Watch from "../../pages/watch/Watch";

export default function Featured({type}){
    return(
        <div className="featured">
            {type && (
                <div className="category">
                    <span>{type === "movie" ? "Movies" : "Series"}</span>
                    <select name="genre" id="genre">
                        <option>Genre</option>
                        <option value="adventure">Adventure</option>
                        <option value="comedy">Comedy</option>
                        <option value="crime">Crime</option>
                        <option value="fantasy">Fantasy</option>
                        <option value="historical">Historical</option>
                        <option value="horror">Horror</option>
                        <option value="romance">Romance</option>
                        <option value="sci-fi">Sci-fi</option>
                        <option value="thriller">Thriller</option>
                        <option value="western">Western</option>
                        <option value="animation">Animation</option>
                        <option value="drama">Drama</option>
                        <option value="documentary">Documentary</option>
                    </select>
                </div>
            )}

            <img src={background_image}
                 alt=""
            />

            <div className="info">
                <img src={show_logo}
                     alt=""
                />
                <span className="desc">
                    A Korean boy meets and Indonesian girl during homeroom in 7th grade.
                    They start talking and by the Senior year of high school, he finds himself
                    falling in love.
                </span>
                <div className="buttons">
                    <a className="play" href="/watch">
                        <PlayArrow/>
                        <span>Watch</span>
                    </a>
                    <a className="more">
                        <InfoOutlined/>
                        <span>Info</span>
                    </a>
                </div>
            </div>
        </div>
    )
}