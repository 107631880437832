import "./register.scss"
import {useRef, useState} from "react";

export default function Register(){

    const occasion = "100 days anniversary"
    const textRef = useRef()
    const [wrongTextStatus, setWrongTextStatus] = useState(false)

    const validateOccasion = ()=>{
        if(textRef.current.value === occasion){
            window.open("/home", "_self")
        }else{
            setWrongTextStatus(true)
            textRef.current.value = ''
        }
    }

    return (
        <div className="register">
            <div className="top">
                <div className="wrapper">
                    <img
                        className="logo"
                        src="https://fontmeme.com/permalink/211121/d01a26e982a10624b7f969abbe79e979.png"
                        alt=""
                    />
                </div>
            </div>
            <div className="container">
                <h1>Welcome to LalaTV!</h1>
                <h2>Store memories. Revisit history.</h2>
                <p>
                    Ready to see? Enter the occasion to access the page.
                </p>
                {
                  wrongTextStatus ? (
                      <div className="input">
                          <input type="text" placeholder="that is not the correct occasion!" ref={textRef}/>
                          <button className="registerButton" onClick={validateOccasion}>Get Started</button>
                      </div>
                  ): (
                      <div className="input">
                          <input type="text" placeholder="what is the occasion?" ref={textRef}/>
                          <button className="registerButton" onClick={validateOccasion}>Get Started</button>
                      </div>
                  )
                }
            </div>
        </div>
    )
}