import "./list.scss"
import ListItem from "../listItem/ListItem"
import {ArrowBackIosOutlined, ArrowForwardIosOutlined} from "@material-ui/icons";
import {useRef, useState} from "react";

export default function List({thumbnailList, listTitle, listTrailer, listCategory, listRate, listDate, listDescription, listGenre}){
    const [isMoved, setIsMoved] = useState(false);
    const [slideNumber, setSliderNumber] = useState(0);
    const [clickLimit, setClickLimit] = useState(window.innerWidth / 230);


    const listRef = useRef();

    const handleClick = (direction) => {
        setIsMoved(true);
        let distance = listRef.current.getBoundingClientRect().x - 50;

        if(direction === "left" && slideNumber > 0){
            setSliderNumber(slideNumber - 1) //if moving one slide at a time, subtract slideNumber by 1
            listRef.current.style.transform = `translateX(${230 + distance}px)`; //230 * slideNumber (5) = 1150
        }
        if(direction === "right" && slideNumber < 10 - clickLimit){
            setSliderNumber((slideNumber + 1)) //if moving one slide at a time, add slideNumber by 1
            listRef.current.style.transform = `translateX(${-230 + distance}px)`; //230 * slideNumber (5) = 1150
        }
    }

    return(
        <div className="list">
            <span className="listTitle">{listTitle}</span>
            <div className="wrapper">
                <ArrowBackIosOutlined
                    className="sliderArrow left"
                    onClick={()=>handleClick("left")}
                    style={{display: !isMoved && "none"}}/>
                <div className="container" ref={listRef}>
                    <ListItem index={0} imgSrc={thumbnailList[0]} vidSrc={listTrailer[0]} category={listCategory[0]} rate={listRate[0]} date={listDate[0]} description={listDescription[0]} genre={listGenre[0]}/>
                    <ListItem index={1} imgSrc={thumbnailList[1]} vidSrc={listTrailer[1]} category={listCategory[1]} rate={listRate[1]} date={listDate[1]} description={listDescription[1]} genre={listGenre[1]}/>
                    <ListItem index={2} imgSrc={thumbnailList[2]} vidSrc={listTrailer[2]} category={listCategory[2]} rate={listRate[2]} date={listDate[2]} description={listDescription[2]} genre={listGenre[2]}/>
                    <ListItem index={3} imgSrc={thumbnailList[3]} vidSrc={listTrailer[3]} category={listCategory[3]} rate={listRate[3]} date={listDate[3]} description={listDescription[3]} genre={listGenre[3]}/>
                    <ListItem index={4} imgSrc={thumbnailList[4]} vidSrc={listTrailer[4]} category={listCategory[4]} rate={listRate[4]} date={listDate[4]} description={listDescription[4]} genre={listGenre[4]}/>
                    <ListItem index={5} imgSrc={thumbnailList[5]} vidSrc={listTrailer[5]} category={listCategory[5]} rate={listRate[5]} date={listDate[5]} description={listDescription[5]} genre={listGenre[5]}/>
                    <ListItem index={6} imgSrc={thumbnailList[6]} vidSrc={listTrailer[6]} category={listCategory[6]} rate={listRate[6]} date={listDate[6]} description={listDescription[6]} genre={listGenre[6]}/>
                    <ListItem index={7} imgSrc={thumbnailList[7]} vidSrc={listTrailer[7]} category={listCategory[7]} rate={listRate[7]} date={listDate[7]} description={listDescription[7]} genre={listGenre[7]}/>
                    <ListItem index={8} imgSrc={thumbnailList[8]} vidSrc={listTrailer[8]} category={listCategory[8]} rate={listRate[8]} date={listDate[8]} description={listDescription[8]} genre={listGenre[8]}/>
                    <ListItem index={9} imgSrc={thumbnailList[9]} vidSrc={listTrailer[9]} category={listCategory[9]} rate={listRate[9]} date={listDate[9]} description={listDescription[9]} genre={listGenre[9]}/>
                </div>
                <ArrowForwardIosOutlined className="sliderArrow right" onClick={()=>handleClick("right")}/>
            </div>
        </div>
    )
}