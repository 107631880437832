import "./home.scss"
import Navbar from "../../components/navBar/Navbar";
import Featured from "../../components/featured/Featured";
import List from "../../components/list/List";
import Footer from "../../components/footer/Footer";
import l1image1 from "../../img/list/You/1.jpg";
import l1image2 from "../../img/list/You/2.jpg";
import l1image3 from "../../img/list/You/3.jpg";
import l1image4 from "../../img/list/You/4.jpg";
import l1image5 from "../../img/list/You/5.jpg";
import l1image6 from "../../img/list/You/6.jpg";
import l1image7 from "../../img/list/You/7.jpg";
import l1image8 from "../../img/list/You/8.jpg";
import l1image9 from "../../img/list/You/9.jpg";
import l1image10 from "../../img/list/You/10.jpg";
import l1trailer from "../../video/listYou trailer.mp4";
import l2image1 from "../../img/list/Me/1.jpg";
import l2image2 from "../../img/list/Me/2.jpg";
import l2image3 from "../../img/list/Me/3.jpg";
import l2image4 from "../../img/list/Me/4.jpg";
import l2image5 from "../../img/list/Me/5.jpg";
import l2image6 from "../../img/list/Me/6.jpg";
import l2image7 from "../../img/list/Me/7.jpg";
import l2image8 from "../../img/list/Me/8.jpg";
import l2image9 from "../../img/list/Me/9.jpg";
import l2image10 from "../../img/list/Me/10.jpg";
import l2trailer from "../../video/listMe trailer.mp4";
import l3image1 from "../../img/list/One_Month/1.jpg";
import l3image2 from "../../img/list/One_Month/2.jpg";
import l3image3 from "../../img/list/One_Month/3.jpg";
import l3image4 from "../../img/list/One_Month/4.jpg";
import l3image5 from "../../img/list/One_Month/5.jpg";
import l3image6 from "../../img/list/One_Month/6.jpg";
import l3image7 from "../../img/list/One_Month/7.jpg";
import l3image8 from "../../img/list/One_Month/8.jpg";
import l3image9 from "../../img/list/One_Month/9.jpg";
import l3image10 from "../../img/list/One_Month/10.jpg";
import l3trailer from "../../video/listUs trailer.mp4";
import l4image1 from "../../img/list/100_Days/1.jpg";
import l4image2 from "../../img/list/100_Days/2.jpg";
import l4image3 from "../../img/list/100_Days/3.jpg";
import l4image4 from "../../img/list/100_Days/4.jpg";
import l4image5 from "../../img/list/100_Days/5.jpg";
import l4image6 from "../../img/list/100_Days/6.jpg";
import l4image7 from "../../img/list/100_Days/7.jpg";
import l4image8 from "../../img/list/100_Days/8.jpg";
import l4image9 from "../../img/list/100_Days/9.jpg";
import l4image10 from "../../img/list/100_Days/10.jpg";

const Home = () => {

    const thumbnailList1 = [l1image1,l1image2, l1image3, l1image4, l1image5, l1image6, l1image7, l1image8, l1image9, l1image10];
    const trailerList1 = [l1trailer, l1trailer, l1trailer, l1trailer, l1trailer, l1trailer, l1trailer,l1trailer,l1trailer,l1trailer];
    const videoList1 = [];
    const categoryList1 = ["Haywon Poster", "Post Sushi Enya", "Halloween", "Color Me Mine", ":)", "?", "??", "Mom", "Jeremy better not answer", "Ready for dinner!"];
    const rateList1 = ["7/10", "7.5/10", "8.5/10", "9/10", "10/10", "9.5/10", "?/10", "9.75/10", "8.5/10", "9/10"];
    const dateList1 = ["Sep 25, 2021", "Sep 25, 2021", "Oct 31, 2021", "Nov 11, 2021", "Nov 14, 2021", "Like 12 years ago or smt", "idek", "Sep 19, 2021", "Nov 21, 2021", "Nov 16, 2021"];
    const descriptionList1 = ["Being the good friends we are, we help Jiayuan make a Coro poster for heidi...well I was a good moral support.",
        "We ate at Sushi Enya for my first time and on our way to 21 Choices, I force you to take pictures while kids play soccer in the background.",
        "Halloween! We walked across to block and summoned Joseph and Kiana upon our request! We also ran to Kevin's house for some reason.",
        "3 hours of painting on ceramics was worth it because our plates and stands turned out perfect (u say urs was bad, but i thought it was so good).",
        "Our one month celebration in lieu of the actual day :D. You look so good in this picture!!",
        "Your look like a princess",
        "No comment",
        "My first favorite picture of you!!! You look like a mom parenting a kid on the wrongs things he did.",
        "It's been too long. I miss you in hawaii. Come back!",
        "You look like a bunny, ready for the next meal to come. Dw, its a good thing because it means you are ____."];
    const genreList1 = ["Coro", "Giant", "Halloween", "Poohbear", "Cutie", "Princess", "Derp", "Parenting", "Patient", "Bunny"];

    const thumbnailList2 = [l2image1, l2image2, l2image3, l2image4, l2image5, l2image6, l2image7, l2image8, l2image9, l2image10];
    const trailerList2 = [l2trailer,l2trailer,l2trailer,l2trailer,l2trailer,l2trailer,l2trailer,l2trailer,l2trailer,l2trailer];
    const videoList2 = [];
    const categoryList2 = ["?", "??", "Peace", "My bald head", "Loner", "The Hottest", "The Cutest", "Toby's Fav", "Halloween", "Color Me Mine"];
    const rateList2 = ["9/10", "8.75/10", "7/10", "8/10", "4/10", "10/10", "9.25/10", "9.5/10", "6.5/10", "8.25/10"];
    const dateList2 = ["Apr 22, 2007", "Jun 14, 2008", "Oct 9, 2021", "Oct 10, 2021", "Nov 2, 2021", "Nov 10, 2021", "Nov 11, 2021", "Nov 11, 2021", "Oct 31, 2021", "Nov 11, 2021"];
    const descriptionList2 = ["I remember take this picture. I was like sprinting to my mom because she was so far away, but all she did was take a picture of me.",
        "I'm actually holding two toys in my hand, which are characters from a game called Maplestory. I used to love that game.",
        "The Robotics people were filming videos of the firework during Homecoming, so I had to show my presence in each video.",
        "This kid accidentally dropped a box of sweets, so I helped him pick it up. I didn't even realize you took a picture.",
        "That one time where I mistook my counselor meeting time and so I had to do it outside the library.",
        "Your favorite picture of me...I think.",
        "My happy day. I took so long to sketch it out in pencil and then to paint it over 3 times. Nonetheless, it turned out well.",
        "After Color Me Mine, I went back to your house. This is a proof that Toby loves me.",
        "We took so many pictures next to this statue. I remember we were watching like episode 5 of Squid game on this day too.",
        "My fingers are oriented in a way where it shows both the peace sign and the rock and roll sign.    "];
    const genreList2 = ["Fast", "Saranghae", "Walking", "Manners", "Alone", "Cutie", "Poohbear", "Cutie", "Halloween", "Ashkey"];

    const thumbnailList3 = [l3image1, l3image2, l3image3, l3image4, l3image5, l3image6, l3image7, l3image8, l3image9, l3image10];
    const trailerList3 = [l3trailer,l3trailer,l3trailer,l3trailer,l3trailer,l3trailer,l3trailer,l3trailer,l3trailer,l3trailer];
    const videoList3 = [];
    const categoryList3 = ["Trader Who?", "Target", "2 Months before today", "COROOOO", "Halloween", "Color Me Mine", "Bloated", "We love sushi dw", "Cruising around target", "Hostage"];
    const rateList3 = ["9.25/10", "9/10", "10/10", "8.5/10", "7.5/10", "8.75/10", "8.25/10", "8.75/10", "9/10", "8/10"];
    const dateList3 = ["Oct 19, 2021", "Oct 27, 2021", "Sep 23, 2021", "Oct 3, 2021", "Oct 31, 2021", "Nov 11, 2021", "Nov 17, 2021", "forgot", "Nov 13, 2021", "Nov 17, 2021"];
    const descriptionList3 = ["Trader Joe run. This day is the first time I try Cava, too. It was so good.",
        "I think this is the first time we went to Target together. I don't know why we went tho. Maybe it was to check out the Halloween costumes.",
        "I asked this really cute girl to Coro school dance and she said yes!",
        "This is my favorite photo from Coro other than the ones on my wall. You actually held up a peace sign (for like the first time)",
        "Have you noticed that of the three lists on this website, each list contains a photo during Halloween and with this statue.",
        "Honestly, I really like your painting of the jar of honey on the stand and the characters corresponding with your Crocs on your plate. I find it so creative and nice!",
        "Us watching Lights out and having to take a picture to show proof to our teachers. We ate all-you-can-eat sushi before this and salju after. Im craving salju.",
        "SUSHI ENYA but with Ashley! My head looks disjointed from my body and you look very stressed (obv not from me)",
        "Lmao I am just staring at you straight up and you are just posing. You were also a very good cart driver: the turns around the blocks were seamless and smooth.",
        "Another photo from Lights out that looks like I'm about to choke you."];
    const genreList3 = ["Shopping", "Shopping", "Coro", "Coro", "Halloween", "Pooh bear", "Dance", "Eating", "Shopping", "Ashkey"];

    const thumbnailList4 = [l4image1, l4image2, l4image3, l4image4, l4image5, l4image6, l4image7, l4image8, l4image9, l4image10];
    const trailerList4 = [];
    const videoList4 = [];
    const categoryList4 = ["Broccoli Goes on a Ride with Princess", "Ela Meets Yoo", "New Year, Same Us", "it's a small world", "Pooh bear Hugs~", "Biking Buddies", "You Looking Scrumpdillyicious", "Mwah", "Sweeping Through", "Jin Yoo"];
    const rateList4 = ["9.75/10", "8/10", "7/10", "10/10", "10/10", "9/10", "9.25/10", "9.5/10", "8.75/10", "8/10"];
    const dateList4 = ["Nov 30, 2021", "Dec 27, 2021", "Jan 1, 2022", "Nov 30, 2021", "Dec 10, 2021", "Dec 21, 2021", "forgot", "Dec 22, 2021", "Jan 21, 2022", "Dec 15, 2021"];
    const descriptionList4 = ["For some reason, I love this picture so much. It was like our first roller coaster and I love the poses in it too. CHEEESE",
        "This is the day you first ate with both of my parents! The language barrier made it not as fun, but the food was so good ...at least, in my opinion. Let's go there again!",
        "Our first new years together :P. I look pretty rough but it was fun walking at night with Toby and seeing the half lit Christmas trees of St. Albans.",
        "I never would've imagined going to Disneyland on a school day. Staying there until like 12 AM, eating so much food, and going on so many rides, it was so wild and fun. My favorite ride was Tarzan (the one that goes through a restaurant)",
        "I also really like this picture. It's just us hugging and I get the pooh bear vibes. Also the christmas tree is such a nice background. Merry Christmas :)",
        "Oh hell yeah, we biking. Back when I used to bike everyday, we would go to Lacy park and just bike around. We should bike more together! ...except I'm gonna start driving soon.",
        "More Lunasia! My first time there and don't forget about Ashley, she was there too. I look weird but you look great, so I wanted to slap this one on this website.",
        "Hey! This is the time when we went to Marugame udon together and went on a little date to shop for my clothes! I thought you looked very cantik. I also still kinda like that green shirt.",
        "Oh my lord, we swept through the polls and won six superlatives in total. And guess what! We won the cutest couples :D. I was so happy that we won (but tbh I knew we would hehe).",
        "This was the cup sleeve event for Jin. It was definitely interesting. Still have the stuff from it and idk where to put it. I'm gonna take you to SUGA one too."];
    const genreList4 = ["DISNEYLAND", "Luscious Food :D", "New Years", "DISNEYLAND", "Delightful Dates", "Entertaining Exercise", "Luscious Food :D", "Dinky Dates", "Perfect Pairs", "Ashkey"];

    return (
        <div className="home">
            <Navbar/>
            <Featured/>
            <List thumbnailList={thumbnailList1} listTitle="You" listTrailer={trailerList1} listCategory={categoryList1} listRate={rateList1} listDate={dateList1} listDescription={descriptionList1} listGenre={genreList1}/>
            <List thumbnailList={thumbnailList2} listTitle="Me" listTrailer={trailerList2} listCategory={categoryList2} listRate={rateList2} listDate={dateList2} listDescription={descriptionList2} listGenre={genreList2}/>
            <List thumbnailList={thumbnailList3} listTitle="One Month" listTrailer={trailerList3} listCategory={categoryList3} listRate={rateList3} listDate={dateList3} listDescription={descriptionList3} listGenre={genreList3}/>
            <List thumbnailList={thumbnailList4} listTitle={"100 Days"} listTrailer={trailerList4} listCategory={categoryList4} listRate={rateList4} listDate={dateList4} listDescription={descriptionList4} listGenre={genreList4}/>
            <Footer/>
        </div>
    )
}

export default Home