import "./listItem.scss"
import {Add, PlayArrow, ThumbDownAltOutlined, ThumbUpAltOutlined} from "@material-ui/icons";
import {useState} from "react";

export default function ListItem({index, imgSrc, vidSrc, category, rate, date, description, genre}) {

    const[isHovered, setIsHovered] = useState(false);

    return (
        <div className="listItem"
             style={{left: isHovered && index * 225 - 50 + index * 2.5}}
            onMouseEnter={()=>setIsHovered(true)}
            onMouseLeave={()=>setIsHovered(false)}>
            <img src={imgSrc}
                 alt=""/>

            {isHovered && (
                <>
                    <video src={imgSrc} autoPlay={true} loop />

                    <div className="itemInfo">
                        <div className="icons">
                            <PlayArrow className="icon"/>
                            <Add className="icon"/>
                            <ThumbUpAltOutlined className="icon"/>
                            <ThumbDownAltOutlined className="icon"/>
                        </div>
                        <div className="itemInfoTop">
                            <span>{category}</span>
                            <span className="limit">{rate}</span>
                            <span>{date}</span>
                        </div>
                        <div className="desc">
                            {description}
                        </div>
                        <div className="genre">{genre}</div>
                    </div>
                </>
            )}
        </div>
    )
}