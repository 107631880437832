import "./app.scss"
import Home from "./pages/home/Home";
import Register from "./pages/register/Register"
import Watch from "./pages/watch/Watch";
import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom"

const App = () => {
  return (
      <Router>
          <div className = "App">
              <div className = "container">
                  <Route path = "/home" render = {props => (
                      <React.Fragment>
                          <Home />
                      </React.Fragment>
                  )} />
                  <Route exact path = "/" render = {props => (
                      <React.Fragment>
                          <Register />
                      </React.Fragment>
                  )} />
                  <Route path = "/watch" render = {props => (
                      <React.Fragment>
                        <Watch />
                      </React.Fragment>
                  )} />
              </div>
          </div>
      </Router>
  );
};

export default App;